import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './core/App/App';
import {store} from './core';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ScrollToTop} from 'components';
import {initConsentToolScript, initGaScript, initPostHogScript} from 'utils';
import * as Sentry from '@sentry/react';


['prod', 'stage'].includes(`${process.env.REACT_APP_ENVIRONMENT_NAME}`) && Sentry.init({
    dsn: "https://e179f4fc30fa4a37ab6bbe9e5337a3aa@sentry.mitgo.tech/199",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["https://cashback.affiliate-stage.crutches.space/", "http://giftmio.com/"],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// i18next.init({
//     lng: 'en'
// }, (err, t) => {
//     if (err) return console.log('something went wrong when init language ', err);
// });

declare global {
    const gtag: any;

    interface Window {
        gtag: any,
        dataLayer: any,
        mindbox: any,
    }
}

initGaScript();
initPostHogScript();
initConsentToolScript();

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop/>
                <App/>
            </BrowserRouter>
        </Provider>
    </StrictMode>,
    document.getElementById('giftmio-app'),
);


