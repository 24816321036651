import React, {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import style from './FastInfo.module.scss';
import fastLogo from 'assets/images/icons/fast.svg';
import {useMedia} from 'hooks';
import {useSelector} from 'react-redux';
import {RootState} from 'core';
import classNames from 'classnames/bind';
import {LandingTypes} from '../../typings';
import {takeAdsName} from '../../consts';

export const FastInfo: FC = () => {
    const isMobile = useMedia();
    const {t, i18n} = useTranslation();
    const { landingType, geoBonus} = useSelector((state: RootState) => state.landingReducer);
    const superInfoClassName = classNames(style.super_info, [style[`super_info_${landingType}`]]);
    const daysInfoClassName = classNames(
        style.days_info, 
        [style[`days_info_${landingType}`], style[`days_info_${i18n.language}`]]
    );
    const joinInfoClassName = classNames(style.join_info, [style[`join_info_${landingType}`]]);

    return (
        <div className={style.info}>
            <div className={style.info_first}>
                <div className={`${daysInfoClassName} ${isMobile ? 'textHeader_07' : 'textHeader_06'}`}>{t('7 days – Cashback Approval Period')}</div>
                <div className={superInfoClassName}>
                    <div className={style.super_info_title}>
                        <img src={fastLogo} alt='SuperCashback'/>
                        <h2 className={`brand_text ${style.super_info_title_text}`}>{t('Super fast cashback')}</h2>
                    </div>
                    <p className={style.super_info_text}>
                        <Trans i18nKey='directLandingFastText'>
                            You don’t have to wait weeks or months to get your cashback. Giftmio gives you a exciting opportunity to get your cashback in just <span className='brand_text'>7 days after making a purchase.</span>
                        </Trans>
                    </p>
                </div>
            </div>
            {((landingType === LandingTypes.DIRECTV2 || landingType.includes(takeAdsName)) && geoBonus)
                && <div className={joinInfoClassName}>
                  <Trans i18nKey='directLandingJoinText' geoBonus={geoBonus}>
                    Join Giftmio now and earn <span className='brand_text'>{{geoBonus}}</span> for registration
                  </Trans>
                </div>}
        </div>
    );
};
