import React, {FC, useMemo} from 'react';
import style from '../privacy/PrivacyPolicy.module.scss';
import {Trans, useTranslation} from 'react-i18next';
import {useBreadcrumbs, useDocumentTitle, useTransObjects} from 'hooks';
import {InfoMenuEnum} from 'typings';
import {useAccountTitle} from '../../hooks';

const Terms: FC = () => {
    const {t} = useTranslation();
    const {getPageTitle, documentTitles} = useTransObjects();
    const title = getPageTitle(InfoMenuEnum.TERMS_OF_USE, true);
    useDocumentTitle(documentTitles[InfoMenuEnum.TERMS_OF_USE]);
    useAccountTitle(title);
    const breadcrumbs = useMemo(() => [{ title: documentTitles[InfoMenuEnum.TERMS_OF_USE] }],
        [documentTitles[InfoMenuEnum.TERMS_OF_USE]]);
    useBreadcrumbs(breadcrumbs);

    return (
        <div className={`${style.content} textBody_01`}>
            <p className={`${style.content__mb_20}`}>{t(`These Terms of Use ("Terms") govern your access to and use of the services provided by GiftMio ("we," "us," or "our") in connection with our cashback project (the "Services"). By accessing or using our Services, you ("Customer") agree to be bound by these Terms.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('1. Acceptance of Terms')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`1.1 Agreement to Terms By accessing or using our Services, you represent that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Services.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`1.2 Modification of Terms We reserve the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. Your continued use of our Services after the posting of any modifications constitutes your acceptance of the revised Terms.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('2. Eligibility and Account')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`2.1 In order to become a Customer of the GiftMio and make use of it, the Customer must go through the registration procedure on the website and accept the Terms.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`2.2 You are responsible for maintaining the confidentiality of your account credentials and are solely responsible for all activities that occur under your account. You must promptly notify us of any unauthorized use or suspected security breach of your account.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`2.3. By registering, the Customer expresses his/her consent to this Terms and guarantees that he/she is an adult or other person acting through the person of his/her legal representatives in full compliance with the legislation of the state of citizenship of the relevant Customer.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('3. Use of Services')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`3.1. We provide a free service for registered users to participate in the cashback program. For this purpose, we make available a service platform (GiftMio), which provides the user with an access to a large number of advertisers. GiftMio provider uses affiliate marketing on the platform, so that an affiliate program generates the links to the advertiser’s shops with an affiliate link in order to receive commissions on sales.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.2. Our Services allow you to access advertisers' websites, make purchases or orders, and earn cashback on qualifying transactions. The cashback amounts and eligibility criteria are determined by the advertisers and may vary. If the advertiser does not provide information about the purchases made by the Customer, or GiftMio does not receive the commission, we, in turn, are entitled not to credit the cashback to the Customer until it obtains this information or the commission, and is not liable to the Customer for the non-credited cashback in this case.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.3. You agree to use our Services in compliance with applicable laws, regulations, and these Terms. You will not engage in any unauthorized or illegal activities, including but not limited to fraud, impersonation, or any activity that may harm our Services or other users.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.4. Third-Party Websites may contain links or references to third-party websites or services that are not owned or controlled by us. We do not endorse or assume any responsibility for the content, privacy practices, or terms of such third-party websites or services. Your use of these third-party websites or services is at your own risk.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.5. GiftMio provides and guarantees the payments only in those currencies and in the ways specified on the site. We are not liable for the inability to receive cash back in currencies other than those specified on the site. GiftMio is not liable and does not compensate any additional expenses, incl. conversion rates that could be charged when withdrawing a cashback to the account opened in a currency other than selected on the site. Cashback is accrued within 7 days of withdrawal. Before paying cash back, GiftMio can performs any additional checking that it requires. Cashback is accrued for a purchase made only if the purchase is actually completed and payment is made. GiftMio obliges that its Customers save the information and the documents confirming that they have paid for the product on the website of the online store (email or screenshot of the payment page, checks, etc.).`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.6. GiftMio reserves the right to decline cashback for a purchased order in case of unconfirmed remuneration by a shop or non-receipt of partner fees for 18 months or more from the date of the purchase.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.7. GiftMio does not sell any products or services but is an advertising platform for Advertisers to place information about their products or services.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.8. GiftMio will not be responsible for the quality of the products or services provided by the Advertisers. All claims relating to the quality of the products or services of the Advertisers will be sent directly to the person who has sold the corresponding product or provided the corresponding service.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.9. GiftMio is not liable for any modification or termination of the activity of the Advertisers, presented on the website, as well as for any influence they may have on the cashback accrual in connection to such changes. We are not liable for any change to or termination of any special offers, promotional codes, and coupons on the Advertisers’ websites.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`3.10. The Customer agrees to provide GiftMio with any requested documents necessary to confirm the information about the purchases. GiftMio reserves the right to unilaterally, at its sole discretion, decline cashback payments and reserves the right to change it at any time prior to the moment of cashback payment and to suspend or completely terminate the access of any Customer to the Service’s services.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('4. Intellectual Property')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`4.1 Ownership. All intellectual property rights in our Services, including but not limited to trademarks, logos, copyrights, and proprietary information, are owned by us or our licensors. You are not granted any rights or licenses, express or implied, in relation to our intellectual property.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`4.2 User Content. You retain ownership of any content you submit, post, or transmit through our Services ("User Content"). By submitting User Content, you grant us a worldwide, non-exclusive, royalty-free, transferable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such User Content for the purpose of providing and promoting our Services.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('5. Limitation of Liability')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`5.1 Disclaimer of Warranties. To the maximum extent permitted by applicable law, our Services are provided on an "as is" and "as available" basis. We make no warranties, whether express, implied, or statutory, regarding the quality, accuracy, reliability, availability, or suitability of our Services for any purpose.`)}</p>
            <p className={`${style.content__mb_20}`}>{t(`5.2 Limitation of Liability. To the maximum extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenue, arising out of or in connection with your use of our Services, regardless of the theory of liability.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('6. Indemnification')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`You agree to indemnify, defend, and hold harmless GiftMio and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, costs, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of our Services or violation of these Terms.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('7. Governing Law and Jurisdiction')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`These Terms and any disputes arising out of or in connection with our Services shall be governed by and construed in accordance with the laws of Germany. Any legal action or proceeding arising out of or in connection with these Terms shall be brought exclusively in the District Court of Stuttgart, and you hereby consent to the personal jurisdiction of such courts.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('8. Severability and Waiver')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms shall remain in full force and effect. Our failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('9. Entire Agreement')}</h3>
            <p className={`${style.content__mb_20}`}>{t(`These Terms constitute the entire agreement between you and GiftMio regarding your use of our Services and supersede any prior agreements, understandings, or representations, whether oral or written.`)}</p>
            <h3 className={`${style.content__sub_title} textHeader_08`}>{t('10. Contact Us')}</h3>
            <p><Trans i18nKey='terms_point_ten'>If you have any questions, concerns, or requests regarding these Terms, please contact us at: <a href='mailto:support@giftmio.com'>support@giftmio.com</a></Trans></p>
            <p>{t(`By accessing or using our Services, you acknowledge that you have read and understood these Terms and agree to be bound by them.`)}</p>

        </div>
    );
}

export {Terms};
export default Terms;
