import React, {FC} from 'react';
import footerStyle from './Footer.module.scss';
import {LayoutContainer} from '../LayoutContainer/LayoutContainer';
import giftMioLogo from '../../assets/images/footer/footer-logo.svg';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useMedia} from 'hooks';
import {Dropdown, DropdownOptionsPosition, DropdownOptionValue, DropdownSizes} from '@design-system/ui-kit';
import {InfoRoutesPathEnum, MainRoutesEnum, RootState, setSiteLanguage} from '../../core';
import {MainLanguages} from 'consts';
import i18n from 'i18next';
import {useDispatch, useSelector} from 'react-redux';
import {InternalLinksEnum} from 'typings';


export const Footer: FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isMobile = useMedia();
    const { siteLang } = useSelector((state: RootState) => state.commonReducer);

    const handleLangChange = (value: DropdownOptionValue | null) => {
        dispatch(setSiteLanguage(value as string))
        i18n.changeLanguage(value as string);
    }
    return (
        <footer className={footerStyle.footer}>
            <div className={footerStyle.footer__top}>
                <LayoutContainer>
                    <div className={`textBodyMedium_03 ${footerStyle.footer__topWrapper}`}>
                        <Link className={footerStyle.footer__topWrapper_logo_btn} to={'/'}>
                            <img className={footerStyle.footer__logo} src={giftMioLogo} alt="GiftMio"/>
                        </Link>
                        <div className={`${footerStyle.footer__links}`}>
                            <Link to={MainRoutesEnum.INFO + InfoRoutesPathEnum.CONTACTS} className={footerStyle.footer__link}>{t('Contacts')}</Link>
                            <Link to={MainRoutesEnum.INFO + InfoRoutesPathEnum.IMPRESSUM} className={footerStyle.footer__link}>{t('Imprint')}</Link>
                            <Link to={MainRoutesEnum.INFO + InfoRoutesPathEnum.FAQS} className={footerStyle.footer__link}>{t('FAQs')}</Link>
                            <Link to={MainRoutesEnum.INFO + InfoRoutesPathEnum.SUPPORT} className={footerStyle.footer__link}>{t('Support')}</Link>
                            <a href={InternalLinksEnum.EXTENSION} target={'_blank'} className={footerStyle.footer__link}>{t('Extension')}</a>
                        </div>
                        <div className={footerStyle.footer__langWrapper}>
                            {!isMobile && <span className={footerStyle.footer__langTitle}>{t('Select language')}</span>}
                            <Dropdown
                                onChange={ handleLangChange }
                                optionsPosition={DropdownOptionsPosition.top}
                                size={DropdownSizes.l}
                                value={siteLang}
                                options={MainLanguages}
                            />
                        </div>
                    </div>
                </LayoutContainer>
            </div>
            <div className={footerStyle.footer__bottom}>
                <LayoutContainer>
                    <div className={`textBody_03 ${footerStyle.footer__bottomWrapper}`}>
                        <div className={footerStyle.footer__linksTerms}>
                            <span>© {new Date().getFullYear()} Giftmio</span>
                            <Link to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.TERMS}`} className={footerStyle.footer__link}>{isMobile ? t('Terms') : t('Terms of use')}</Link>
                            <Link to={`${MainRoutesEnum.INFO}${InfoRoutesPathEnum.PRIVACY}`} className={footerStyle.footer__link}>{t('Privacy policy')}</Link>
                        </div>
                    </div>
                    {isMobile && <div className={footerStyle.footer__bottom_moblie}></div>}
                </LayoutContainer>
            </div>
        </footer>
    );
};
